/**
 * = Spacing grids
 */

// .row.row-grid > [class*="col-"] + [class*="col-"] {
//     margin-top: 3rem;
// }

// @each $breakpoint in map-keys($grid-breakpoints) {
//     @include media-breakpoint-up($breakpoint) {
//         .row.row-grid > [class*="col-#{$breakpoint}-"] + [class*="col-#{$breakpoint}-"] {
//             margin-top: 0;
//         }
//     }
// }

// .row-grid + .row-grid {
//     margin-top: 3rem;
// }
