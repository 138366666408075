/*

=========================================================
* Swipe - Mobile App One Page Bootstrap 5 Template
=========================================================

* Product Page: https://themesberg.com/product/bootstrap/swipe-free-mobile-app-one-page-bootstrap-5-template
* Copyright 2020 Themesberg (https://www.themesberg.com)

* Coded by https://themesberg.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. Contact us if you want to remove it.

*/

@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@200;300;400;500;700&display=swap');

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "swipe/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "swipe/vendor";

// Pixel mixins & functions
@import "swipe/mixins";
@import "swipe/functions";

// Utilities
@import "swipe/reboot";
@import "swipe/utilities";

// Layout
@import "swipe/layout";

// Components
@import "swipe/components";

// write your custom styles here!